<template>
  <div class="secret" v-if="isHtsySecret">
    <a-form-item
      v-if="isForm"
      label="密级"
      :name="field"
      :rules="{
        required: !disabled && true,
        validator: minNum,
        trigger: 'change',
      }"
    >
      <a-radio-group
        v-model:value="selectVal"
        :disabled="disabled"
        @change="radioChange"
      >
        <a-radio-button
          v-for="item in !isPerson ? options : options2"
          :key="item.value"
          :value="item.value"
        >
          {{ item.label }}
        </a-radio-button>
      </a-radio-group>
    </a-form-item>
    <a-radio-group
      v-else
      v-model:value="selectVal"
      :disabled="disabled"
      @change="radioChange"
    >
      <a-radio-button
        v-for="item in !isPerson ? options : options2"
        :key="item.value"
        :value="item.value"
      >
        {{ item.label }}
      </a-radio-button>
    </a-radio-group>
  </div>
</template>
<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "secret", // 密级选择
  props: {
    field: {
      // secret字段名
      type: String,
      default: "secretData",
    },
    value: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isPerson: {
      // 是人
      type: Boolean,
      default: false,
    },
    options: {
      // 物
      type: Array,
      default: [
        { label: "非密", value: 50 },
        { label: "秘密", value: 70 },
        { label: "机密", value: 80 },
      ],
    },
    options2: {
      // 人
      type: Array,
      default: [
        { label: "公开", value: 50 },
        { label: "一般", value: 70 },
        { label: "重要", value: 80 },
        { label: "核心", value: 90 },
      ],
    },
    isForm: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["secret:change"],
  setup(props, { emit }) {
    const selectVal = computed(() => props.value);

    const minNum = (rule, value, callback) => {
      if (value <= 0) {
        return Promise.reject("请选择密级");
      }
      return Promise.resolve();
    };

    const radioChange = (e) => {
      emit("secret:change", e.target.value);
    };

    const store = useStore();
    // 平台配置是否开启密级
    const isHtsySecret = computed(
      () => store.getters.platformConfig.htsySecret == 1
    );

    return {
      selectVal,
      minNum,
      radioChange,
      isHtsySecret,
    };
  },
});
</script>
<style lang="less" scoped>
.secret {
  .ant-form-vertical {
    .ant-form-item {
      flex-direction: row;
    }
    .ant-form-item-control-wrapper {
      width: unset;
    }
  }
}
</style>
